<template>
    <div>
        <section>
            <b-card>
                <div class="row">
                    <div class="col-12 col-md-5 mb-3">
                        <MainCard />
                    </div>
                    <div class="col-12 col-md-7 mb-3">
                        <Chart />
                    </div>
                </div>
            </b-card>
        </section>
        <section class="mt-4">
            <Transactions />
        </section>
    </div>
</template>
<script>
import { mapActions, mapState } from 'vuex'
import MainCard from './MainCard.vue'
import Chart from './Chart.vue'
import Transactions from './Transactions.vue'
export default {
    components: {
        MainCard,
        Chart,
        Transactions
    },
    data () {
        return {
            loading: false,
            payload: {
                page: 1,
                search: null
            },
            total: 0,
            fields: [
                {tdClass: 'text-left', thClass: 'text-left', key: 'user', label: 'Username' },
                {tdClass: 'text-left', thClass: 'text-left', key: 'title', label: 'Description' },
                {tdClass: 'text-left', thClass: 'text-left', key: 'type', label: 'Type' },
                {tdClass: 'text-left', thClass: 'text-left', key: 'price', label: 'Price' },
                {tdClass: 'text-left', thClass: 'text-left', key: 'payment', label: 'Payment' },
                {tdClass: 'text-left', thClass: 'text-left', key: 'status', label: 'Status' }
            ],
        }
    },
    computed: {
        ...mapState('cart', ['carts'])
    },
    watch: {
        'payload.page': function (val) {
            this.payload.page = val
            this.getData()
        }
    }
}
</script>